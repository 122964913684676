import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import TwoColorHeader from "../components/atoms/TwoColorHeader";
import LoginModal from "../components/organisms/LoginModal";

const IndexPage = () => {
  const pageName = "What are NFTs?";
  const questionList1 = [
    {
      q: "Does it cost anything to join Metal+?",
      a: "No. It is completely free to join. ",
    },
    {
      q: "Do I need to have MetaMask to use Metal+?",
      a: "Yes. All Metal+ account require a MetaMask wallet to be able to collect the exciting web3 collectibles that are available. ",
    },
    {
      q: "What is a digital/crypto wallet?",
      a: "A crypto wallet is a place where you can safely store all you cryptocurrencies and all the amazing web3 content you can collect from Metal+.",
    },
  ];

  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const handleLogin = () => {
    setShowLoginModal(true);
  };

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageName}`}</title>
      </Helmet>
      <div className="pt-36 pb-24 px-12 sm:px-36 max-w-[1200px] mx-auto">
        <div className="mb-12 px-0 lg:px-40">
          <p>
            Metal+ is the first of its kind blockchain enabled publishing house.
            For over 80 years comics have had 2 main purposes,
          </p>
          <ul className="pl-4 py-2">
            <li>1. To be enjoyed.</li>
            <li>2. To hold its value.</li>
          </ul>
          <p>
            Since the dawn of digital publishing point #2 has been an
            impossibility, no use of technology could solve this critical
            difference between the two mediums. Until now.
          </p>
        </div>
        <div className="flex flex-col gap-y-12">
          <div>
            <TwoColorHeader
              title="Getting|Started"
              colorClassB="text-primary"
              className="font-bold text-5xl uppercase mb-4 drop-shadow-md"
            />
            <ul className="list-disc flex flex-col gap-y-8">
              <li>
                <p className="text-xl font-bold">How do I make an account?</p>
                <p>
                  You can sign for your free Metal+ account{" "}
                  <button className="text-primary" onClick={handleLogin}>
                    here
                  </button>
                  .
                </p>
              </li>
              {questionList1.map((item, index) => (
                <li key={index}>
                  <p className="text-xl font-bold">{item.q}</p>
                  <p>{item.a}</p>
                </li>
              ))}
              <li>
                <p className="text-xl font-bold">What is MetaMask?</p>
                <p>
                  MataMask is a 3rd party digital wallet provider that is one of
                  the most trusted and widely used digital wallets on the web.
                  It is available as a browser extension and as a mobile app,
                  MetaMask equips you with a key vault, secure login, token
                  wallet, and token exchange—everything you need to manage your
                  digital assets. To find out more about MetaMask you can watch
                  their explainer{" "}
                  <a
                    href="https://www.youtube.com/watch?v=YVgfHZMFFFQ"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    video here
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://metamask.io/faqs/"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    visit their webpage here
                  </a>
                  .
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  Does it cost money to set up a MetaMask account?
                </p>
                <p>No. Setting up a MetaMask account is free. </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  How do I set up a MetaMask account?{" "}
                </p>
                <p>
                  <a
                    href="https://metamask.io/faqs/"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit their webpage here
                  </a>{" "}
                  and follow the steps. If you are having troubles please jump
                  into the{" "}
                  <a
                    href="https://discord.com/invite/sJYsWTWkBm"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Heavy Metal Discord
                  </a>{" "}
                  here and one of our team will help you out.{" "}
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  I am having trouble with my/setting up my MetaMask account,
                  can you help?
                </p>
                <p>
                  <a
                    href="https://metamask.io/faqs/"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit their webpage here
                  </a>{" "}
                  and follow the steps. If you are having troubles please jump
                  into the{" "}
                  <a
                    href="https://discord.com/invite/sJYsWTWkBm"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Heavy Metal Discord
                  </a>{" "}
                  here and one of our team will help you out.{" "}
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  Do I need to own cryptocurrencies to buy Metal+ web3
                  collectibles?
                </p>
                <p>
                  No, you don’t need to own any cryptocurrency to use Metal+, we
                  accept all major cards.{" "}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <TwoColorHeader
              title="web3|Collectibles"
              colorClassB="text-primary"
              className="font-bold text-5xl uppercase mb-4 drop-shadow-md"
            />
            <ul className="list-disc flex flex-col gap-y-8">
              <li>
                <p className="text-xl font-bold">
                  What does non-fungible mean?
                </p>
                <p>
                  If something is non-fungible it means it is unique, and can
                  not be directly replaced byanything else. Money is a fungible
                  asset, one $2 coin can be swapped for any other $2 coin.
                  Artwork (or music or trading cards) are non-fungible, one
                  painting can not beswapped for another even if they are
                  created by the same artist.
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  What is an NFT/web3 collectible?
                </p>
                <p>
                  An NFT or Non-Fungible Token is a web3 asset that represents
                  objects like comics, magazines, and art. Each unique Metal+
                  web3 collectible is stored on the blockchain, so whenever you
                  buy one rest assured that it’s a 100% official licensed
                  collectable from your favourite Metal+ creator. Each NFT has
                  its own unique identifying code that can never be changed
                  which means that ownership and rarity are fully verifiable.
                  Over time there will be 1000’s of web3 collectibles on Metal+
                  and each will have its own complete history authenticity
                  stored in the code.
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">What is blockchain?</p>
                <div>
                  A blockchain is a publicly accessible online ledger or
                  database that is not owned by any central authority. Once
                  anything is set in this ledger, it cannot be modified or
                  censored by any single authority. This gives Blockchain
                  technology several key features that give authority and
                  security to digital collecting:
                  <ul className="list-disc flex flex-col gap-y-4 pl-12 mt-2">
                    <li>
                      Scarcity:When an NFT is uploaded to the blockchain, total
                      number of editions is set and can not be changed. No more
                      than the defined number of editions, will ever be
                      released. This means if a piece is 1 of 100, no more than
                      100 editions can ever be created.
                    </li>
                    <li>
                      Authenticity:As there can be no changes to the blockchain
                      after an NFT has been minted, collectors can trust that
                      the artwork they are purchasing is the original and has
                      not been tampered with, because the publicly accessible
                      data on the blockchain is always available to verify it.
                      Even if other people attempt to copy the NFT in the
                      future, it will be easily identified as a replica and the
                      value of your artwork will not degrade
                    </li>
                    <li>
                      Ownership:Every transfer/purchase of an NFT, is recorded
                      on the blockchain. This means that there is a publicly
                      accessible ownership history of every asset. Provenance is
                      automated and accurate. This both ensures the legitimacy
                      of the NFT but also allows the original artist to be
                      compensated for all future sales.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <p className="text-xl font-bold">What is a wallet?</p>
                <p>
                  A wallet is where you access your web3 currency and assets.
                  There are 100s of different wallets both online and there are
                  also hardware versions. At Metal+ we are using MetaMask and
                  industry leader in wallet security and usability.{" "}
                </p>
              </li>
              <li>
                <p className="text-xl font-bold">
                  How do I know my web3 collectibles will be safe and secure?
                </p>
                <p>
                  No one can access the web3 collectibles in your wallet. Not
                  even Metal+. Once you own it only you can approve the sale or
                  transfer of your web3 collectibles. It’s vitally important to
                  protect your Metal+ wallet like you would any personal
                  accounts online. Keep your passwords safe and don’t share them
                  with anyone.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showLoginModal && (
        <LoginModal
          handleCloseModal={setShowLoginModal.bind(this, false)}
          showLoginModal={showLoginModal}
        />
      )}
    </Layout>
  );
};

export default IndexPage;
